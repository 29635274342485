var $ = require("jquery");
var breakpoint = require('./app-breakpoints');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width(); 
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");
var width = $(window).width(); 
function initMap(mapinfo) {
	
	if ($.isArray(mapinfo.datalat) && $.isArray(mapinfo.datalng)) {
		var myLat = mapinfo.datalat;
		var myLng = mapinfo.datalng;
		var myLatLng = { lat: myLat[0].value, lng: myLng[0].value };
	} else {
		var myLatLng = { lat: mapinfo.datalat, lng: mapinfo.datalng };
	}

	if (mapinfo.center) {
		var center_map = mapinfo.center;
	} else {
		var center_map = myLatLng;
	}

	var styles = [
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#444444"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#a6ce39"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"visibility": "simplified"
				},
				{
					"color": "#73a533"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#f2f2f2"
				}
			]
		},
		{
			"featureType": "landscape.natural.landcover",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#73a533"
				}
			]
		},
		{
			"featureType": "landscape.natural.landcover",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#a6ce39"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#73a533"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"saturation": -100
				},
				{
					"lightness": 45
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"color": "#46bcec"
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#006caa"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#009fc3"
				}
			]
		}
	];

	//console.log(center_map);

	var map = new google.maps.Map(document.getElementById(mapinfo.id), {
		zoom: mapinfo.zoom,
		disableDefaultUI: mapinfo.disableDefaultUI,
		draggable: mapinfo.draggable,
		draggableCursor: mapinfo.draggableCursor,
		mapTypeId: mapinfo.maptype,
		center: center_map,
		mapTypeControl: false,
		scaleControl: true,
		streetViewControl: false,
		fullscreenControl: false,
		zoomControl: true,
		scrollwheel: false,
		styles: styles

	});
	if (mapinfo.disableDefaultUI == false) {

		var image = document.location.origin + '/app/themes/wp-thermostat/dist/pin_default.png';

		var bounds = new google.maps.LatLngBounds();
		var infowindow = new google.maps.InfoWindow();

		var marker, i;

		for (i = 0; i < myLat.length; i++) {
			//image = document.location.origin + '/app/themes/wp-thermostat/dist/pin_' + (i + 1) + '.png';
			marker = new google.maps.Marker({
				position: new google.maps.LatLng(myLat[i].value, myLng[i].value),
				map: map,
				icon: image

			});

			//extend the bounds to include each marker's position
			bounds.extend(marker.position);

			// google.maps.event.addListener(marker, 'click', (function (marker, i) {
			// 	return function () {
			// 		infowindow.setContent(locations[i][0]);
			// 		infowindow.open(map, marker);
			// 	}
			// })(marker, i));


			/*google.maps.event.addListener(marker, 'click', function () {
				console.log(marker);
				console.log('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
				window.open('https://www.google.com/maps/dir/Current+Location/' + marker.lat + ',%20' + marker.lng);
			});*/
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {

					window.open('https://www.google.com/maps/dir/Current+Location/' + myLat[i].value + ',%20' + myLng[i].value);
				}
			})(marker, i));
		}
		//now fit the map to the newly inclusive bounds
		if (myLat.length > 1) {
			map.fitBounds(bounds);
		}
	}
}

window.preparMap = function () {
	//bounds = new google.maps.LatLngBounds();
	//infowindow = new google.maps.InfoWindow();
	var datalat = "";
	var datalng = "";
	var id = "";
	var disableDefaultUI = false;
	var draggable = true;
	var draggableCursor = '';
	var zoom = 10;
	var marker = "default";
	var maptype = "roadmap";
	var center;
	$('.vy_google_map').each(function () {
		datalat = $(this).data('lat');
		datalng = $(this).data('lng');
		// console.log(datalat[0].value);
		// console.log(datalng);

		if ($(this).attr('data-zoom')) {
			zoom = parseInt($(this).data('zoom'));
		}
		if ($(this).attr('data-center-lat') && $(this).attr('data-center-lng')) {
			center = { lat: $(this).data('center-lat'), lng: $(this).data('center-lng') };
		}
		if ($(this).attr('data-marker')) {
			marker = $(this).data('marker');
		}
		if ($(this).attr('data-maptype')) {
			maptype = $(this).data('maptype');
		}
		id = $(this).attr('id');
		if ($(this).data('disableui') == true) {
			disableDefaultUI = $(this).data('disableui');
			draggable = false;
			draggableCursor = 'default';
		}

		// console.log('---vy_google_map---');
		// console.log('ID: ' + id);
		// console.log('LAT: ' + datalat);
		// console.log('LNG: ' + datalng);
		// console.log('center: ');
		// console.dir(center);
		// console.log('zoom: ' + zoom);
		// console.log('disableDefaultUI: ' + disableDefaultUI);
		// console.log('draggable: ' + draggable);
		// console.log('draggableCursor: ' + draggableCursor);
		// console.log('marker: ' + marker);
		var mapinfo = { datalat: datalat, datalng: datalng, id: id, zoom: zoom, disableDefaultUI: disableDefaultUI, draggable: draggable, draggableCursor: draggableCursor, marker: marker, center: center, maptype: maptype };
		initMap(mapinfo);
	});
}

function loadScript(callback) {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
		'&callback=' + callback + '&key=AIzaSyBk35MM8MfXm3AROtxlDAc2aBChagvAfJ4'; 
	document.body.appendChild(script);
}
if (document.getElementsByClassName("vy_google_map").length > 0) {
	window.onload = loadScript('preparMap');
}
function changeProductBox(){	
	var boxID = $('.vy_listing_detail.active').attr('id');
	if (boxID) {
		// console.log(boxID);
		boxID = boxID.replace('vy_detail_', '');
		var item = $('.vy_listing_item[data-detail-id=' + boxID + ']');
		console.log(item);
		
		var boxHeight = $('.vy_listing_detail.active').outerHeight(true);
		var itemHeight = $(item).outerHeight();
		var itemPosition = $(item).position();
		var itemPositionTop = itemPosition.top;
		var boxPositionTop = itemHeight + itemPositionTop;
		// console.log(boxID);
		console.log('ID ' + boxID);
		console.log('boxHeight ' + boxHeight);
		console.log('itemHeight ' + itemHeight);
		console.log('itemPositionTop ' + itemPositionTop);
		console.log('boxPositionTop ' + boxPositionTop);
		$(item).css('margin-bottom', boxHeight);
		$('.vy_listing_detail.active').css('top', boxPositionTop);
	}
}
$(function() {
	
	$('.vy_listing_item').click(function(){
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$('.vy_listing_detail').removeClass('active');
			$(this).css('margin-bottom', '');
		}else{
			$('.vy_listing_item').css('margin-bottom', '');
			var boxID = $(this).data('detail-id');
			var boxHeight = $('#vy_detail_' + boxID).outerHeight(true);
			var itemHeight = $(this).outerHeight();
			var itemPosition = $(this).position();
			var itemPositionTop = itemPosition.top;
			var boxPositionTop = itemHeight + itemPositionTop;
			console.log('ID ' + boxID);
			console.log('boxHeight ' + boxHeight);
			console.log('itemHeight ' + itemHeight);
			console.log('itemPositionTop ' + itemPositionTop);
			console.log('boxPositionTop ' + boxPositionTop);
			
			$('.vy_listing_item').removeClass('active');
			$('.vy_listing_detail').removeClass('active');
			$(this).addClass('active');
			$(this).css('margin-bottom',boxHeight);
			$('#vy_detail_' + boxID).addClass('active').css('top', boxPositionTop);
			$([document.documentElement, document.body]).animate({
				scrollTop: $(this).offset().top
			}, 1000);
			
		}
	})
	$('.vy_listing_detail_close').click(function () {
		$('.vy_listing_item').removeClass('active');
		$('.vy_listing_detail').removeClass('active');
		$('.vy_listing_item').css('margin-bottom', '');
	})

  $(window).resize(function(){
		// console.log('TEST RESIZE');
		// console.log('base' + width);
		
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');
		if (screen.width !== width) {
			// console.log('IF RESIZE');
			changeProductBox();
			// console.log('if base' + width);
			width = $(window).width(); 
			// console.log('screen' + width);
		}
		// if(_gridLabel == "smartphone") {
		
		// }

	});

});