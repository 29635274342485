import UIkit from 'uikit';
import Icons from './uikit/dist/js/uikit-icons';

UIkit.use(Icons);

import './scss/main.scss';
import './js/common'

var $ = require("jquery");
$(document).ready(function(){
	if ( $("#modal-popup").length ) {
		UIkit.modal($("#modal-popup")).show();
	}
});